<template>
  <PayoutBalanceComponent title="SPs Fee Owed" balanceType="negative" />
</template>

<script>
import PayoutBalanceComponent from '@/components/PayoutBalanceComponent';

export default {
  name: 'SpFeeOwed',
  components: {
    PayoutBalanceComponent,
  },
};
</script>

<style></style>
